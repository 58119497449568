import React from "react";

import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";

export default ({ data }) => {
  // const featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid;
  return (
    <Layout>
      <div className="section">
        <div className="container" style={{ paddingTop: "8rem" }}>
          <div className="columns">
            <div className="column is-three-quarters">
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <div
                  key={node.id}
                  style={{
                    maxWidth: "600px",
                    margin: "auto",
                    paddingBottom: "5rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Link to={node.fields.slug}>
                    <Img
                      style={{
                        maxWidth: "600px",
                        maxHeight: "600px",
                        margin: "auto",
                      }}
                      fluid={
                        node.frontmatter.featuredImage.childImageSharp.fluid
                      }
                    />
                  </Link>
                  <h3
                    className="has-text-primary is-size-3"
                    style={{ paddingTop: "3rem" }}
                  >
                    {node.frontmatter.title}
                    {/* <span>— {node.frontmatter.date}</span> */}
                  </h3>
                  <p className="subtitle" style={{ paddingBottom: "2rem" }}>
                    {node.excerpt}
                  </p>
                  <Link
                    to={node.fields.slug}
                    className="button is-danger"
                    style={{ alignSelf: "flex-end", marginRight: "2rem" }}
                  >
                    Read Article
                  </Link>
                  <hr />
                </div>
              ))}
            </div>
            <div className="column">
              <h1 className="is-size-4 has-text-black">Posts</h1>
              <ul>
                {data.allMarkdownRemark.edges.map(({ node }) => (
                  <li style={{ margin: "1rem 0" }} key={node.id}>
                    <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;
